.about {
  text-align: left;

  :global {
    .name {
      font-size: 30px;
    }

    .description {
      font-size: 16px;
      line-height: 32px;
      text-align: left;
      margin-bottom: 40px;
    }

    ul {
      padding: 0;
    }

    li {
      list-style: none;
    }

    .exhibition {
      font-style: italic;
    }
  }
}
