@import '~antd/dist/antd.css';

.App {
  text-align: center;
  height: 100%;
}

.ant-image-preview-img-wrapper {
  padding: 45px 0;
}

.ant-image-preview-img {
  border: 2px solid #fff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-item {
  color: #999;
}

.ant-menu-item-selected {
  color: #111;
}

.ant-menu-item:hover {
  color: #111;
}

.ant-menu-item:active {
  background-color: #fff;
}
