.work {
  :global {
    .title {
      font-size: 24px;
    }

    .title-desc {
      font-size: 16px;
      font-weight: 400;
    }

    .description {
      text-align: left;
      color: #888;
      font-size: 16px;
    }

    .ant-image {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
