.footer {
  padding: 12px 20px;
  font-size: 14px;
  color: #888;
  background-color: #fff;

  :global {
    p {
      margin: 0;
    }

    .notice {
      font-weight: 700;
    }
  }
}
