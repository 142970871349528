@fontColor: #999;
@hoverColor: #111;

.header {
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0px 88px;
  height: 80px;
  align-items: center;

  :global {
    .title-wrapper {
      cursor: pointer;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 40px;
        font-weight: 700;
        line-height: initial;
        margin: 0;
      }

      .description {
        margin: 0;
        margin-top: -10px;
        line-height: initial;
      }
    }

    .menu {
      text-align: right;
    }

    .ant-menu {
      color: @fontColor;
      border: none;
      width: 100%;

      .ant-menu-submenu {
        border: none;

        &:hover {
          border: none;
          color: @hoverColor;
        }
      }

      .ant-menu-submenu-title {
        &:hover {
          color: @hoverColor;
        }
      }

      .ant-menu-submenu-selected {
        color: @hoverColor;
      }

      .ant-menu-item {
        border: none;

        &:hover {
          border: none;
          color: @hoverColor;
        }
      }

      .ant-menu-item-selected {
        color: @hoverColor;
      }
    }
  }
}
