.home {
  height: 100%;
  width: 100%;

  :global {
    .title {
      font-size: 40px;
    }

    .wrapper {
      display: flex;
      justify-content: center;
    }

    .image-wrapper {
      position: relative;
      width: 450 * 0.9px;
      height: 600 * 0.9px;

      .image-mask {
        color: #eee;
        text-align: center;
        line-height: 600 * 0.9px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.3s;
      }

      .image {
        width: 450 * 0.9px;
        height: 600 * 0.9px;
      }

      &:hover {
        .image-mask {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}
